import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { PricingTaxVerse, linkResolver } from '@pwc-new-ventures/ui';
import Layout from '../layouts';

const postPrefix = 'insights';

export default function() {
  const { contentfulPricingPage } = useStaticQuery(graphql`
    {
      contentfulPricingPage(slug: { eq: "pricing" }) {
        metaTitle
        metaDescription
        image {
          ...ImageSharpMedium
        }
        heroHeadline
        heroDescription {
          json
        }
        heroBackgroundImage {
          ...ImageSharpOriginal
        }
        pricingCards {
          cardType
          id
          inclusionHeadline
          price
          price2
          price2EyebrowText
          priceEyebrowText
          pricing2FrequencyText
          pricingFrequencyText1
          pricingFrequencyText2
          onboardingText {
            json
          }
          inclusionChecklist {
            text {
              text
            }
          }
          ctaText {
            json
          }
          primaryCta {
            ctaText
            ctaLink {
              ...NavigatorCtaLink
            }
          }
          secondaryCta {
            ctaText
            ctaLink {
              ...NavigatorCtaLink
            }
          }
        }
        pricingFaqHeadline
        pricingFaQs {
          question
          answer {
            json
          }
        }
        spacerNavigator {
          headline
          blurb {
            json
          }
          ctaText
          ctaLink {
            ...NavigatorCtaLink
          }
        }
      }
    }
  `);

  const doc = {
    meta: {
      title: contentfulPricingPage.metaTitle,
      description: contentfulPricingPage.metaDescription,
      image:
        contentfulPricingPage.image &&
        contentfulPricingPage.image.localFile.childImageSharp.fluid.src,
    },
    pricing: {
      headline: contentfulPricingPage.heroHeadline,
      body: contentfulPricingPage.heroDescription,
      background:
        contentfulPricingPage.heroBackgroundImage &&
        contentfulPricingPage.heroBackgroundImage.localFile.publicURL,
      tiers: contentfulPricingPage.pricingCards.map((teir) => ({
        id: teir.id,
        headline: teir.cardType,
        onboarding: teir.onboardingText,
        prices: [
          {
            eyebrow: teir.priceEyebrowText,
            footnote: teir.pricingFrequencyText2,
            frequency: teir.pricingFrequencyText1,
            price: teir.price,
          },
          {
            eyebrow: teir.price2EyebrowText,
            footnote: null,
            frequency: teir.pricing2FrequencyText,
            price: teir.price2,
          },
        ],
        featuresHeadline: teir.inclusionHeadline,
        features: teir.inclusionChecklist.map(({ text }) => text.text),
        ctaText: teir.ctaText,
        primaryCta: teir.primaryCta && {
          buttonText: teir.primaryCta.ctaText,
          buttonUrl:
            teir.primaryCta.ctaLink &&
            linkResolver({
              type: teir.primaryCta.ctaLink[0].internal.type,
              slug: teir.primaryCta.ctaLink[0].redirectUrl || teir.primaryCta.ctaLink[0].slug,
              postPrefix,
            }),
        },
        secondaryCta: teir.secondaryCta && {
          buttonText: teir.secondaryCta.ctaText,
          buttonUrl:
            teir.secondaryCta.ctaLink &&
            linkResolver({
              type: teir.secondaryCta.ctaLink[0].internal.type,
              slug: teir.secondaryCta.ctaLink[0].redirectUrl || teir.secondaryCta.ctaLink[0].slug,
              postPrefix,
            }),
        },
      })),
    },
    navigator: {
      headline: contentfulPricingPage.headline,
      ctaText: contentfulPricingPage.ctaText,
      ctaLink: contentfulPricingPage.ctaLink,
    },
    faq: {
      headline: contentfulPricingPage.pricingFaqHeadline,
      pricingFaQs:
        contentfulPricingPage.pricingFaQs &&
        contentfulPricingPage.pricingFaQs.map((faq) => ({
          question: faq.question,
          answer: faq.answer.json.content[0].content[0].value,
        })),
    },
    cta: {
      headline: contentfulPricingPage.spacerNavigator.headline,
      buttonText: contentfulPricingPage.spacerNavigator.ctaText,
      buttonUrl:
        contentfulPricingPage.spacerNavigator.ctaLink &&
        linkResolver({
          type: contentfulPricingPage.spacerNavigator.ctaLink[0].internal.type,
          slug:
            contentfulPricingPage.spacerNavigator.ctaLink[0].redirectUrl ||
            contentfulPricingPage.spacerNavigator.ctaLink[0].slug,
          postPrefix,
        }),
      background: 'accent-1',
      noRule: true,
      primary: true,
    },
  };

  return (
    <Layout title={doc.meta.title} description={doc.meta.description} image={doc.meta.image}>
      <PricingTaxVerse doc={doc} Link={Link} />
    </Layout>
  );
}
